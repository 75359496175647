<template>
  <div class="text-center q-py-md q-px-lg">
    <h6 class="q-mx-lg">
      {{ $t('login.log_in_or_join', { partner: $store.getters.partner.name }) }}
    </h6>
    <q-btn color="primary" class="full-width q-mt-sm" :label="$t('sign_up')" @click="$router.push({ name: 'Logout', params: { signup: true } })" />
    <q-btn color="primary" outline class="full-width q-mt-md" :label="$t('login.log_in')" @click="$router.push({ name: 'Logout'})" />
  </div>
</template>

<script>
export default {
  name: 'GuestBlocker'
}
</script>
